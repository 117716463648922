import { MeetingApi } from '@/api/meeting/MeetingApi';

export class MeetingService {
    private api = new MeetingApi();

    public meetingSearch(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.meetingSearch(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}