import { MeetingUserApi } from '@/api/meeting/MeetingUserApi';

export class MeetingUserService {
    private api = new MeetingUserApi();

    public listTeamUser(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.listTeamUser(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}