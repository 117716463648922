<template>
  <ion-menu
    side="end"
    menu-id="StoreChoose"
    class="my-custom-menu"
    content-id="ProjectMainProjectList"
    :swipeGesture="false"
    @ionDidOpen="initData()"
    @ionDidClose="(listQuery.page = 0),(updateList = []), (listQuery.localeCode = '')"
  >
    <ion-content class="select-user-dept-team">
      <ion-header class="theme2">
        <ion-toolbar>
          <p slot="start" style="font-size: 14px">
            <span class="iconfont icon-renwushaixuan"></span>&nbsp;库位选择
          </p>
          <ion-buttons slot="end" class="close">
            <ion-button @click="onClose()">
              <span class="iconfont icon-duihao"></span>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <div class="meetingPeople">
        <ion-grid>
          <ion-row class="ion-row-flex">
            <ion-col size="12" class="ion-col-common add-text-align-left">
              <div class="searchBox">
                <SearchBar
                  :isAudio="false"
                  theme="1"
                  :searchValue="listQuery.localeCode"
                  @on-change="searchHandler"
                  placeholder="搜索库位"
                ></SearchBar>
              </div>
            </ion-col>
            <!-- <ion-col size="1" class="ion-col-common add-text-align-right">
              <ion-img
                :src="require('@/assets/images/sys.png')"
                @click="sysKw"
              ></ion-img>
            </ion-col> -->
          </ion-row>
        </ion-grid>
        <!-- List of Text Items -->
        <ion-list>
          <ion-item
            v-for="(item, index) in dataTable"
            :key="index"
            class="itemsprojectuser"
          >
            <ion-label>{{ item.localeCode }}</ion-label>
            <ion-checkbox
              class="selectradio"
              v-model="item.isChecked"
              @ionChange="changeBox(item)"
              slot="end"
            ></ion-checkbox>
          </ion-item>
        </ion-list>
        <div v-show="taskLoading" class="loading">加载中...</div>
        <CardLoading
          v-if="dataTable.length <= 0 && islist"
          lodingType="1"
        ></CardLoading>
      </div>
      <ion-infinite-scroll
        @ionInfinite="initData($event)"
        v-if="!noMore"
        threshold="100px"
        id="infinite-scroll"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="加载中..."
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-menu>
</template>

<script>
import CardLoading from "@/components/ListCard/CardLoading.vue";
import {
  IonList,
  IonItem,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from "@ionic/vue";
import { defineComponent } from "vue";
// import HeaderModal from "../../components/modal/components/HeaderModal.vue";
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import { MeetingService } from "@/services/meeting/MeetingService";
import { MeetingUserService } from "@/services/meeting/MeetingUserService";
import { AccountService } from "@/services/login/accountService";
import Utils from "../../utils/Utils";
import { getLocaleCode } from "@/api/storeManagement/storeChoose";
import { menuController, modalController, popoverController } from "@ionic/vue";
export default defineComponent({
  name: "StoreChoose",
  components: {
    IonText,
    IonCol,
    IonGrid,
    IonRow,
    IonLabel,
    IonItem,
    IonList,
    Header,
    CardLoading,
    SearchBar,
  },
  props: {
    ids: "",
    stocks: "",
  },
  data() {
    return {
      meetingService: new MeetingService(),
      meetingUserService: new MeetingUserService(),
      accountService: new AccountService(),
      rowsUser: new Array(),

      listQuery: {
        localeCode: "",
        page: 0,
        pageSize: 20,
      },
      taskLoading: false,
      optionShow: true,
      searchList: new Array(),
      rowUserIdList: new Array(),
      useOptionList: new Array(),
      selectIndex: -1,
      dataTable: [],
      islist: false,
      stockLoacleIds: "", //库位id集合
      localeCodes: "", //库位编号集合
      updateList: [],
      isDisabled: false,
      sendList: [],
      sendListlocaleCode: [],
      noMore: false,
      showScan: false,
      storeCode: "",
    };
  },

  created() {},
  mounted() {
    // this.initData();
    console.log("mounted");
  },
  watch: {},
  methods: {
    initData(ev) {
      return new Promise((resolve) => {
        this.islist = true;
        this.listQuery.page += 1;
        if (ev) {
          ev.target.complete();
        }
        getLocaleCode(this.listQuery).then((res) => {
          this.islist = false;
          if (this.listQuery.page == 1) {
            this.dataTable = [];
          }
          this.dataTable = this.dataTable.concat(
            res.data.data.resultData || []
          );
          if (this.dataTable.length <= 0 && ev) {
            ev.target.disabled = true;
          }
          this.findSelected()
          resolve();
        });
      });
    },
    searchHandler(searchParam) {
      if (Utils.isInCludeEmoji(searchParam.searchKey)) {
        Utils.presentToastWarning("搜索内容不可以包含表情");
        return;
      }
      var scanVal = searchParam.searchKey;
      if (scanVal.indexOf('"') > -1) {
        this.listQuery.localeCode = "";
        if (scanVal.indexOf("localeCode") > -1) {
          var contents = JSON.parse(
            "{" + scanVal.slice(scanVal.indexOf('"')) + "}"
          );
          this.listQuery.localeCode = contents.localeCode;
        } else {
          this.listQuery.localeCode = "";
          Utils.presentToastTopWarning("请正确扫描二维码", "danger");
        }
      } else {
        this.listQuery.localeCode = searchParam.searchKey;
      }

      this.noMore = false;
      this.listQuery.page = 0;
      this.initData();
    },
    onClose() {
      this.$emit(
        "choose",
        this.sendList.join(","),
        this.sendListlocaleCode.join(",")
      );
      menuController.close();
    },
    changeBox(item) {
      console.log(this.sendList, 11);
      if (item.isChecked) {
        if (
          !this.sendList.some((e) => {
            return e == item.id;
          })
        ) {
          this.sendList.push(item.id);
          this.sendListlocaleCode.push(item.localeCode);
        }
      } else {
        if (this.sendList.indexOf(item.id) > -1) {
          this.sendList.splice(this.sendList.indexOf(item.id), 1);
          this.sendListlocaleCode.splice(
            this.sendListlocaleCode.indexOf(item.localeCode),
            1
          );
        }
      }
    },
    async findSelected() {
      this.sendList = [];
      this.sendListlocaleCode = [];
      var demoList = [];
      var list2 = this.ids.split(",");
      if (list2[0] != "") {
        demoList = demoList.concat(
          this.dataTable.filter((res) => {
            var bool = true;
            if (
              list2.some((e) => {
                return e == res.id;
              })
            ) {
              bool = false;
            }
            return bool;
          })
        );
        this.dataTable = demoList;
      }

      var list = this.ids ? this.ids.split(",") : [];
      var list1 = this.stocks ? this.stocks.split(",") : [];
      this.sendList = list;
      this.sendListlocaleCode = list1;
      console.log(this.sendList, this.sendListlocaleCode);
    },
    closeScan(contents) {
      this.showScan = false;
      this.searchHandler(contents.localeCode);
    },
    sysKw() {
      menuController.close();
      this.$emit("transfer");
    },
  },
});
</script>

<style scoped lang="scss">
ion-content {
  --ion-background-color: #ffffff !important;
}
.select-user-dept-team {
  .searchBox {
    width: calc(100% - #{px2rem(30)});
    margin: 0 auto;
  }

  .meetingPeople {
    margin: px2rem(10) auto;
    .ion-row-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .add-text-align-left {
        color: #52596d;
        text-align: left;
      }
      .add-text-align-right {
        color: #3487ff;
        text-align: right;
        ion-img {
          width: 18px;
        }
      }
      .ion-col-common {
        font-size: px2rem(28);
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: 0px;

        .add-ion-icon {
          vertical-align: middle;
        }
        .add-ion-text {
          vertical-align: middle;
        }
      }
    }
    p {
      font-size: $default-font-size;
    }
  }
}
ion-input {
  background: #f1f6fd;
  border-radius: 3rem;
  padding: 0.8rem 1rem;
  width: 31.25rem;
  height: 3.625rem;
  font-size: 1.375rem;
}
.itemsprojectuser {
  --background: #f6f6f6;
  --inner-border-width: 0px 0px 0px 0px;
  margin: px2rem(12px) px2rem(42px) 0 px2rem(40px);
  border-radius: px2rem(8px);
  height: px2rem(88);
}
.loading {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}
</style>
