<template >
  <ion-page>
    <Header type="0" title="扫码上架"> </Header>

    <ion-content scroll-y="true" class="content" id="ProjectMainProjectList">
      <!-- 无数据  -->
      <div class="no_data" v-if="tableList.length == 0 && !islist">
        <img
          class="no_data_img no_data_top"
          src="@/assets/images/notDate/notSearch.png"
          alt
        />
        <p class="no_data_text">未查询到信息</p>
      </div>
      <div
        v-for="(item, index) in tableList"
        :key="item.id"
        class="app-container"
      >
      <div
          class="item"
        >
        <div class="title">{{ item.stageCode }}</div>
          <div class="title" style="text-align: right;">{{ item.partCode }}</div>
        </div>
        <div
          class="item"
          style="border-bottom: 1px solid #e2e2e2; padding-bottom: 15px"
        >
        
          <div class="title">{{ item.partNameCN }}</div>
          <div class="rate">{{ item.partVersion }}</div>
        </div>
        
        <!-- <div class="item" style="margin-top: 10px">
          <div class="item-label">零件编号</div>
          <div class="item-content">{{dataItem.partCode}}</div>
        </div> -->
        <div class="item">
          <div class="item-label">数量</div>
          <div class="item-content">{{ item.trialNum }}</div>
        </div>
        <div
          class="item"
          style="border-bottom: 1px solid #e2e2e2; padding-bottom: 25px"
        >
          <div class="item-label">库位</div>
          <div class="item-content">
            <ion-text @click.stop="storeReview(item, idx)">
              <span class="addBorderRight">{{ item.localeCodes }}</span>
            </ion-text>
            <ion-icon
              @click="addTaskHandler(item, index)"
              class="icon"
              :icon="qrCode"
            ></ion-icon>
          </div>
        </div>
      </div>
      <CardLoading
        v-if="tableList.length <= 0 && islist"
        lodingType="1"
      ></CardLoading>
    </ion-content>
    <ion-footer>
      <ion-button @click="submit" expand="block">提交</ion-button>
    </ion-footer>
    <StoreChoose
      :ids="stockLoacleIds"
      @choose="choose"
      :stocks="stocks"
      @transfer="transfer"
    ></StoreChoose>
    <scanner v-if="showScan" @closeScan="closeScan"></scanner>
  </ion-page>
</template>
<script>
import {
  alertController,
  ionAlert,
  IonSelect,
  IonSelectOption,
  menuController,
  loadingController,
  modalController,
} from "@ionic/vue";
import Header from "@/components/Header.vue";
import uploadImg from "@/components/getPhoto";
import { qrCode } from "ionicons/icons";
import {
  getPutAway,
  updateLocaleCode,
} from "@/api/storeManagement/sweepCodeOn";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import Utils from "@/utils/Utils.ts";
import SystemUtils from "@/utils/SystemUtils";
import StoreChoose from "./storeChoose.vue";
import scanner from "@/components/cordova/qrScanner.vue";
import storeReview from "./storeReview.vue";
export default {
  components: {
    IonSelect,
    IonSelectOption,
    Header,
    uploadImg,
    CardLoading,
    ionAlert,
    StoreChoose,
    scanner,
    storeReview,
  },
  data() {
    return {
      qrCode,
      tableList: [],
      modalTableList: [], //userId总清单
      islist: false, // isloading
      isOpenRef: false, // 确认框
      idx: 0,
      stockLoacleIds: "",
      showScan: false,
      stocks: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    addTaskHandler(val, idx) {
      this.idx = idx;
      console.log(val.stockLoacleIds)
      if (val.stockLoacleIds&&val.stockLoacleIds.indexOf("(") > -1) {
        var str = val.stockLoacleIds
          ? val.stockLoacleIds.substr(1, val.stockLoacleIds.length - 2)
          : "";

        this.stockLoacleIds = str.split(")(").join(",");
      } else {
        this.stockLoacleIds = val.stockLoacleIds||'';
      }
      this.stocks = val.localeCodes || "";
      menuController.enable(true, "StoreChoose");
      menuController.open("StoreChoose");
    },
    getList() {
      this.islist = true;
      getPutAway().then((res) => {
        this.islist = false;
        this.tableList = res.data.data.resultData;
      });
    },

    transfer(type) {
      this.showScan = true;
    },
    choose(val, val1) {
      this.tableList[this.idx].stockLoacleIds = val;
      this.tableList[this.idx].localeCodes = val1;
      console.log(typeof val);
      // this.stockLoacleIds = val;
    },
    async submit() {
      const _this = this;
      var list = _this.tableList.filter((e) => {
        return (
          e.stockLoacleIds != null &&
          e.stockLoacleIds != "" &&
          e.stockLoacleIds != undefined
        );
      });
      if (list.length == 0) {
        Utils.presentToastTopWarning("无可上架零件", "danger");
        return false;
      }
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "提示",
        message: "是否提交",
        buttons: [
          {
            text: "取消",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              console.log("Confirm Cancel");
            },
          },
          {
            text: "确定",
            handler: async () => {
              const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "请稍等...",
                duration: 50000,
              });
              await loading.present();

              updateLocaleCode({ datas: list }).then((res) => {
                loading.dismiss();
                if (!res.data.isError && res.data.data.isSuccess) {
                  _this.$router.go(-1);
                  Utils.presentToastTopWarning("操作成功", "success");
                } else {
                  Utils.presentToastTopWarning(
                    res.data.message || res.data.data.message,
                    "danger"
                  );
                }
              });
            },
          },
        ],
        ionAlertDidPresent: (e) => {
          console.log(e);
        },
      });
      await alert.present();

      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    },
    test() {
      this.closeScan({ id: 4832, localeCode: "A03-01-33" });
    },
    closeScan(contents) {
      this.showScan = false;
      if (contents.id && contents.localeCode) {
        if (this.stockLoacleIds.indexOf(contents.id) == -1) {
          var list = this.stockLoacleIds ? this.stockLoacleIds.split(",") : [];
          list.push(contents.id);
          var list1 = this.tableList[this.idx].localeCodes
            ? this.tableList[this.idx].localeCodes.split(",")
            : [];
          list1.push(contents.localeCode);
          this.choose(list.join(","), list1.join(","));
        } else {
          Utils.presentToastTopWarning("此零件已上架该库位", "danger");
        }
      } else {
        Utils.presentToastTopWarning("请扫描零件二维码", "danger");
      }
    },
    async storeReview(item, idx) {
      var _this = this;
      console.log(item.localeCodes, item.stockLoacleIds);
      const modal = await modalController.create({
        component: storeReview,
        cssClass: "my-custom-class",
        componentProps: {
          localeCodes: item.localeCodes,
          stockLoacleIds: item.stockLoacleIds,
        },
      });
      modal.present();
      var data = await modal.onDidDismiss();
      if (!data.data) {
        return false;
      }
      var list = data.data.list;
      var list1 = [];
      var list2 = [];
      list.forEach((e) => {
        list1.push(e.id);
        list2.push(e.str);
      });
      this.tableList[idx].stockLoacleIds = list1.join(",");
      this.tableList[idx].localeCodes = list2.join(",");
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-lingbujianxinxi {
  font-size: 48px;
}
ion-button {
  --background: #00529c;
}

.star {
  color: red;
  padding-top: px2rem(10);
}
.root:last-child {
  margin-top: px2rem(15);
}
.root {
  width: calc(100% #{px2rem(58)});
  height: auto;
  margin: px2rem(20) auto;
  background: #ffffff;
  border-radius: $border-radius;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: px2rem(20) px2rem(45);
  font-size: #{$default-font-size};
}
.lefttwo {
  width: px2rem(270) !important;
  height: auto !important;
  line-height: px2rem(38) !important;
  // text-align: left;
  color: $color !important;
}
.item {
  .title {
    width: 100% !important;
    font-size: px2rem(28) !important;
    font-weight: 700;
    ion-textarea {
      --placeholder-color: #8e8e8e;
      --placeholder-opacity: 1;
    }
  }
}

.app-container {
  width: calc(100% - 2.5rem);
  height: auto;
  background: #ffffff;
  border-radius: 0.875rem;
  margin: 1.25rem auto;
  box-shadow: 0 0 1rem 0 rgba(21, 40, 102, 0.1);
  padding: 1.25rem 2.8125rem;
  font-size: 1.625rem;
  div {
    padding: 5px 0;
  }
  .title {
    width: 100%;
    font-size: 13px;
    font-weight: 700;
  }
  .rate {
    font-size: 15px;
    font-weight: 500;
    color: #1782e3;
  }
  ion-item {
    --background: #ffffff;
    --padding-start: 0;
  }
  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-content {
      .addBorderRight {
        display: inline-block;
        padding-right: px2rem(20);
        margin-right: px2rem(20);
        height: px2rem(36);
        border-right: 2px solid rgba(151, 151, 151, 0.3);
        width: 95px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    > .item-label {
      width: 40%;
      font-size: 10px;
      opacity: 0.5;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
    }
  }
}

.ion-header-tab {
  margin: #{px2rem(30px)} #{$left-right-padding-width} 0;
  width: calc(100% -2 * #{$left-right-padding-width});
}
.search_box {
  width: 100%;
  background-color: $panel-input-background;
}
.search-fixed {
  width: 85%;
  position: relative;
}
.pull-down {
  position: absolute;
  top: px2rem(20);
  left: 85%;
  height: 3.75rem;
  line-height: 3.75rem;
  background-color: $ion-background;
  width: 25%;
  border-radius: 2.875rem;
  .iconxialaliebiao {
    margin-left: 10%;
    color: $font-placeholder-color;
    font-size: px2rem(24);
  }
  .fengge {
    margin-left: 55%;
    color: $font-placeholder-color;
    font-weight: 700;
    opacity: 0.5;
    font-size: $very-big-font-size;
    margin-top: -0.5rem;
  }
}
</style>