import MeetingBaseService from './base/MeetingBaseService';

export class MeetingUserApi extends MeetingBaseService {
    constructor() {
        super("meetingUser");
    }

    /**
     * 检索团队或用户
     *  let params = {
            'page': 1,
            'size': 1000,
            'teamId': teamId,
            'queryTotal': false
        }
    */
    public listTeamUser(params: any): Promise<any> {
        return this.request('listTeamUser', this.rest.METHOD_GET, params);
    }
}