import MeetingBaseService from './base/MeetingBaseService';

export class MeetingApi extends MeetingBaseService {
    constructor() {
        super("meeting");
    }

    /**
     * 检索团队或用户
     * let params = {
            'page': 1,
            'size': 100,
            'keyword': keyword,//团队或用户名称
            'queryTotal': false
        }
    */
    public meetingSearch(params: any): Promise<any> {
        return this.request('listSearch', this.rest.METHOD_GET, params);
    }
}